import { useEffect } from "react";

import { useAuthStore } from "@/stores/auth";

export default function useCheck() {
  console.log("check");
  const canEnter = useAuthStore((s) => s.canEnter);
  const setCanEnter = useAuthStore((s) => s.setCanEnter);

  useEffect(() => {
    if (!canEnter) {
      const input = prompt("Pass");
      if (input === "emw") {
        setCanEnter(true);
        window.location.reload();
      }
    }
  }, [canEnter, setCanEnter]);

  return { canEnter };
}
